<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>问答</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div></div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" @row-click="handleRowClick" row-key="id" border ref="tab">
                <el-table-column prop="title" align="center" label="页面"></el-table-column>
                <el-table-column prop="answer" align="center" label="问题"></el-table-column>
                <el-table-column prop="ask" align="center" label="答案"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_add" v-if="row.pid == 0">
                            <button @click="openAdd(row)">添加</button>
                        </div>
                        <div class="_operate" v-if="row.pid != 0">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                        <div class="_delete" v-if="row.pid != 0">
                            <button @click="del(row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog
            :title="form.id ? '编辑' : '添加'"
            :visible.sync="dialogStatus"
            width="50%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form label-width="90px">
                <el-form-item label="问题">
                    <el-input v-model.trim="form.answer"></el-input>
                </el-form-item>
                <el-form-item label="答案">
                    <el-input v-model.trim="form.ask"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogStatus = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
export default {
    data() {
        return {
            tableData: [],
            dialogStatus: false,
            form: {},
        };
    },
    async created() {
        this.tableData = await this.getData();
    },

    methods: {
        async getData() {
            let { data } = await axios.get("/api/ask_answer/show");
            return data;
        },
        openAdd(row) {
            this.form = {
                ask: "",
                answer: "",
                pid: row.id,
            };
            this.dialogStatus = true;
        },
        openSet(row) {
            this.form = { ...row };
            this.dialogStatus = true;
        },
        async yes() {
            let { form } = this;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/ask_answer/edit", QueryString.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/ask_answer/add", QueryString.stringify(form));
            }
            this.$message({
                type: data.code == 200 ? "success" : "error",
                message: data.msg,
            });
            this.dialogStatus = false;
            this.tableData = await this.getData();
        },
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/ask_answer/del", { params: { id } });
            this.$message({
                type: data.code == 200 ? "success" : "error",
                message: data.msg,
            });
            this.tableData = await this.getData();
        },
        // 点击行
        handleRowClick(row, column, event) {
            if (event.target.tagName === "BUTTON") return;
            this.$refs["tab"].toggleRowExpansion(row);
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
    .page_headerSecondary {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
